import React, { useEffect } from "react";
import css from "./Login.module.css";
import FirebaseUI from "../../components/firebase/FirebaseAuthUI";

const Login = () => {
  useEffect(() => {
    FirebaseUI("#firebase");
  });

  return (
    <div className={css.view}>
      <div className={css.wrapper}>
        <div>Login</div>
        <div className={css.firebaseUIWrapper}>
          <div id="firebase" />
        </div>
      </div>
    </div>
  );
};

export default Login;
