import React, { useState } from "react";
import css from "./Header.module.css";
import Sidebar from "../sidebar/Sidebar";
import { IoMdMenu } from "react-icons/io";

const Header = () => {
  const [sideBarOpen, toggleSideBar] = useState(false);

  return (
    <div className={css.header}>
      <div className={css.navigation}>
        <div
          className={css.sidebarButton}
          onClick={() => toggleSideBar(!sideBarOpen)}
        >
          <IoMdMenu />
        </div>
      <img src="/logo1.svg" alt="" className={css.logo} />
      </div>
      <Sidebar isOpen={sideBarOpen} toggleSideBar={toggleSideBar} />
      <div
        className={sideBarOpen ? css.overlay : undefined}
        onClick={() => toggleSideBar()}
      />
    </div>
  );
};

export default Header;
