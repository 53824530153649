import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import css from "./Group.module.css";
import { LOGIN, VOTING, LEADERBOARD } from "../../constants/routes";
import firebase from "../../components/firebase/Firebase";
import { withUser } from "../../components/firebase/UserContext";

const onJoin = async (event, groupId, history, setGroupId) => {
  try {
    event.preventDefault();
    const group = firebase.firestore().collection("groups").doc(groupId);

    if (!(await group.get()).exists) {
      alert("Group not found");
      return;
    }

    if (!firebase.auth().currentUser) {
      await firebase.auth().signInAnonymously();
    }

    await group
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .set({ login: new Date() }, { merge: true });

    setGroupId(groupId);
    history.push(VOTING);
  } catch (error) {
    console.log(error);
    alert("Something went wrong");
  }
};

const onCreate = async (event, newGroupId, history, setGroupId) => {
  try {
    event.preventDefault();
    const group = firebase.firestore().collection("groups").doc(newGroupId);

    await group.set({ admin: firebase.auth().currentUser.uid });

    await group
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .set({ login: new Date() }, { merge: true });

    setGroupId(newGroupId);
    history.push(LEADERBOARD);
  } catch (error) {
    alert("Something went wrong");
    console.error(error);
  }
};

const Group = ({ history, user, groupId, setGroupId }) => {
  const [inputGroupId, setInputGroupId] = useState(groupId);
  const [newGroupId, setNewGroupId] = useState("");

  useEffect(() => {
    const { search } = history.location;
    if (search) {
      const params = new URLSearchParams(search);
      const id = params.get("id");
      setInputGroupId(id);
    }
  }, [history.location]);

  return (
    <div className={css.view}>
      <div className={css.join}>
        <div className={css.text}>Join a group {!user && "anonymously"}</div>
        <form
          className={css.form}
          onSubmit={(e) => onJoin(e, inputGroupId, history, setGroupId)}
        >
          <input
            placeholder="Group id"
            type="text"
            value={inputGroupId}
            className={css.input}
            onChange={(e) => setInputGroupId(e.target.value.toLowerCase())}
          />
          <input className={css.button} type="submit" value="JOIN" />
        </form>
      </div>
      {!user || user.isAnonymous ? (
        <div className={css.join}>
          <div className={css.text}>
            Or&nbsp;
            <Link to={LOGIN}>login&nbsp;</Link>
            to create a group
          </div>
        </div>
      ) : (
        <div className={css.join}>
          <div className={css.text}>Create a group</div>
          <form
            className={css.form}
            onSubmit={(e) => onCreate(e, newGroupId, history, setGroupId)}
          >
            <input
              placeholder="Unique group id"
              className={css.input}
              value={newGroupId}
              onChange={(e) => setNewGroupId(e.target.value.toLowerCase())}
            />
            <input className={css.button} type="submit" value="CREATE" />
          </form>
        </div>
      )}
    </div>
  );
};

export default withUser(Group);
