import React, { useState, useEffect } from "react";
import css from "./Voting.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import firebase from "../../components/firebase/Firebase";
import { withUser } from "../../components/firebase/UserContext";

const countries = require("../../constants/countries.json");

const db = firebase.firestore();
const subscribeToFirestore = (setPoints, groupId) =>
  db
    .collection("groups")
    .doc(groupId)
    .collection("users")
    .doc(firebase.auth().currentUser.uid)
    .onSnapshot((doc) => {
      const data = doc.data();
      data && data.points && setPoints(data.points);
    });

const getPoints = (index) => {
  if (index > 10) return 0;
  if (index >= 2 && index <= 10) return 10 - index;
  if (index === 1) return 10;
  if (index === 0) return 12;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const onSave = (items, groupId) => {
  const points = items.map((x, index) => ({
    ...x,
    points: getPoints(index),
  }));

  db.collection("groups")
    .doc(groupId)
    .collection("users")
    .doc(firebase.auth().currentUser.uid)
    .set({ points }, { merge: true });
  alert("Your vote has been saved! You may leave now.");
};

const onDragEnd = (items, result, setItems) => {
  if (!result.destination) {
    return;
  }

  const newItems = reorder(
    items,
    result.source.index,
    result.destination.index
  );
  setItems(newItems);
};

const mapCountries = (list) =>
  list.map((country, index) => (
    <Draggable
      key={country.alpha3Code}
      draggableId={country.alpha3Code}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          className={css.listItem}
          style={provided.draggableProps.style}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className={css.row}>
            <div className={css.rowLeft}>
              <img src={country.flag} className={css.flag} alt="" />
              <div className={css.countryDetails}>
                <div className={css.name}>{country.name}</div>
                <div className={css.artist}>
                  {country.artist || "Very long artist name"}
                </div>
                <div className={css.artist}>
                  {country.song || "Even longer song name"}
                </div>
              </div>
            </div>
            <div>{getPoints(index)}</div>
          </div>
        </div>
      )}
    </Draggable>
  ));
const Voting = ({ groupId }) => {
  const [items, setItems] = useState(countries);

  useEffect(() => {
    const unsubscribe = subscribeToFirestore(setItems, groupId);
    return () => {
      unsubscribe();
    };
  }, [groupId]);

  return (
    <div className={css.view}>
      <div className={css.title}>
        Choose your order
        <button
          className={css.saveButton}
          onClick={() => onSave(items, groupId)}
        >
          Save
        </button>
      </div>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(items, result, setItems)}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div>
              <div className={css.list} ref={provided.innerRef}>
                {mapCountries(items)}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default withUser(Voting);
