import * as firebaseui from "firebaseui";
import firebase from './Firebase'
import { GROUP } from "../../constants/routes";

// const provider = new firebase.auth.GoogleAuthProvider();
// firebase.auth().useDeviceLanguage();
// firebase.auth().signInWithRedirect(provider);

const uiConfig = {
  signInSuccessUrl: GROUP,
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ]
};

const ui = new firebaseui.auth.AuthUI(firebase.auth());

const firebaseAuthUI = elementId => {
  ui.start(elementId, uiConfig);
};

export default firebaseAuthUI;
