import React from "react";
import css from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => (
  <div className={css.view}>
    <div className={css.policy}>
      <h1>Privacy policy</h1>
      <p>
        Adding new groups is restricted to logged in users. Your email addressis
        saved and used to identify the group admin. The data you save when using
        the app, i.e. your voting desicion will be linked to your user account.
      </p>
      <p>
        We will not email you or contact you in any other way, unless it's
        regarding a group you have created. This is but a hobby project to help
        a damsel in distress.
      </p>
      The app is hosted in Firebase hosting, so we cannot vouch for Firebase or
      Google to not steal your data though.
    </div>
  </div>
);

export default PrivacyPolicy;
