import React, { createContext, useState } from "react";
import { useEffect } from "react";
import firebase from "./Firebase";

const UserContext = createContext({
  user: "",
  group: "",
  setGroupId: () => {},
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState("");
  const [groupId, updateGroupId] = useState(
    localStorage.getItem("groupId") || ""
  );

  useEffect(() => {
    firebase.auth().onAuthStateChanged((userData) => {
      setUser(userData || "");
    });
  }, []);

  const setGroupId = (id) => {
    updateGroupId(id);
    localStorage.setItem("groupId", id);
  };

  return (
    <UserContext.Provider value={{ user, groupId, setGroupId }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserConsumer = ({ children }) => (
  <UserContext.Consumer>{children}</UserContext.Consumer>
);

export const withUser = (Component) => (props) => (
  <UserContext.Consumer>
    {(context) => (
      <Component
        {...props}
        user={context.user}
        groupId={context.groupId}
        setGroupId={context.setGroupId}
      />
    )}
  </UserContext.Consumer>
);

export default UserContext;
