import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { VOTING, LOGIN, GROUP, LEADERBOARD, POLICY } from "./constants/routes";
import Voting from "./views/voting/Voting";
import Header from "./components/header/Header";
import Login from "./views/login/Login";
import Group from "./views/group/Group";
import Leaderboard from "./views/leaderboard/Leaderboard";
import { UserProvider, UserConsumer } from "./components/firebase/UserContext";
import PrivacyPolicy from "./views/policy/PrivacyPolicy";

const Routes = () => (
  <UserConsumer>
    {({ user, groupId }) => (
      <Router>
        <Header />
        <div className="route">
          <Switch>
            <Route exact path={POLICY} component={PrivacyPolicy} />
            <Route exact path={LOGIN} component={Login} />
            {!user || !groupId ? (
              <Route component={Group} />
            ) : (
              <Switch>
                <Route exact path={GROUP} component={Group} />
                <Route exact path={VOTING} component={Voting} />
                <Route exact path={LEADERBOARD} component={Leaderboard} />
              </Switch>
            )}
          </Switch>
        </div>
        <Route exact path="/" component={() => <Redirect to={GROUP} />} />
      </Router>
    )}
  </UserConsumer>
);

const App = () => (
  <UserProvider>
    <Routes />
  </UserProvider>
);

export default App;
