import React from "react";
import css from "./Sidebar.module.css";
import classNames from "classnames";
import { MdGroup, MdFormatListBulleted, MdStar } from "react-icons/md";
import { IoIosLogIn, IoIosLogOut } from "react-icons/io";
import { Link } from "react-router-dom";
import { GROUP, LEADERBOARD, VOTING } from "../../constants/routes";
import firebase from "../firebase/Firebase";

const Sidebar = ({ isOpen, toggleSideBar }) => {
  const handleLogout = async () => {
    await firebase.auth().signOut();
    toggleSideBar();
  };

  return (
    <div className={classNames(css.sidebar, { [css.open]: isOpen })}>
      <div className={css.links}>
        <Link to={GROUP} className={css.row} onClick={() => toggleSideBar()}>
          <MdGroup />
          <div className={css.rowText}>Group</div>
        </Link>
        <Link
          to={LEADERBOARD}
          className={css.row}
          onClick={() => toggleSideBar()}
        >
          <MdFormatListBulleted />
          <div className={css.rowText}>Score board</div>
        </Link>
        <Link to={VOTING} className={css.row} onClick={() => toggleSideBar()}>
          <MdStar />
          <div className={css.rowText}>Vote</div>
        </Link>
      </div>
      <div className={css.bottom}>
        {firebase.auth().currentUser ? (
          <div className={css.row} onClick={handleLogout}>
            <IoIosLogOut />
            <div className={css.rowText}>Log out</div>
          </div>
        ) : (
          <Link to="/login" className={css.row} onClick={() => toggleSideBar()}>
            <IoIosLogIn />
            <div className={css.rowText}>Login</div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
