export const LANDING = "/";
export const LOGIN = "/login";

// Join or create a group
export const GROUP = "/group";

// Set countries
// Show country list https://restcountries.eu/#api-endpoints-code
// Select countries, artist, song
export const ADMIN = "/admin";

// Give your vote
// Show country list https://restcountries.eu/#api-endpoints-code
// Set order
export const VOTING = "/voting";

// Score board
export const LEADERBOARD = "/board";

// Privacy policy
export const POLICY = "/privacy";
