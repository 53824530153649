import React, { useEffect, useState } from "react";
import css from "./Leaderboard.module.css";
import firebase from "../../components/firebase/Firebase";
import { withUser } from "../../components/firebase/UserContext";
import classnames from "classnames";

const db = firebase.firestore();

const subscribeToFirestore = (setPoints, groupId) =>
  db
    .collection("groups")
    .doc(groupId)
    .collection("users")
    .get()
    .then((snapshot) => {
      const users = snapshot.docs.map((doc) => doc.data());
      let newPoints = [];
      users.forEach((user) => {
        user.points &&
          user.points.forEach((country) => {
            const item = newPoints.find(
              (x) => x.alpha3Code === country.alpha3Code
            );
            if (item) {
              item.points += country.points;
            } else {
              newPoints.push({ hidden: true, ...country });
            }
          });
      });
      setPoints(newPoints);
    });

const Leaderboard = ({ groupId }) => {
  const [points, setPoints] = useState([]);

  useEffect(() => {
    subscribeToFirestore(setPoints, groupId);
  }, [groupId]);

  const toggleHidden = (id) => {
    const newPoints = [...points];
    newPoints[id].hidden = !newPoints[id].hidden;
    setPoints(newPoints);
  };

  return (
    <div className={css.view}>
      <div className={css.title}>Total score</div>
      <div className={css.list}>
        {points
          .sort((a, b) => b.points - a.points)
          .slice(0, 10)
          .map((country, index) => (
            <div
              key={index}
              className={css.item}
              onClick={() => toggleHidden(index)}
            >
              <div
                key={index}
                className={classnames(
                  css.row,
                  country.hidden ? [css.hidden] : [css.revealed]
                )}
              >
                <div className={css.rowLeft}>
                  <img src={country.flag} className={css.flag} alt="" />
                  <div className={css.countryDetails}>
                    <div className={css.name}>{country.name}</div>
                    <div className={css.artist}>
                      {country.artist || "Very long artist name"}
                    </div>
                    <div className={css.artist}>
                      {country.song || "Even longer song name"}
                    </div>
                  </div>
                </div>
                <div className={css.score}>{country.points}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default withUser(Leaderboard);
